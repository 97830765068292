body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rec-slider-container.rec.jNKBoY {
  margin: 0px 0px;
}

 /*
@font-face {
  font-family: "Poppins";
  font-weight: normal;
  src: url("../public/assets/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Cairo";
  font-weight: normal;
  src: url("../public/assets/fonts/cairo_regular.ttf") format("truetype");
}
::-webkit-scrollbar{
    display: none
}*/

.swiper-container {
  width: 80%; /* adjust the width to your desired value */
}
